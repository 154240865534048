import Card from "./Card";
import Nav from "./Nav";
import { useState } from "react";

const Layout = () => {
  const [userInput, setUserInput] = useState("All");

  return (
    <>
      <Nav setUserInput={setUserInput} userInput={userInput} />
      <Card userInput={userInput} />
    </>
  );
};

export default Layout;
