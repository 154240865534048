import styled from "styled-components";
import { useAllPrismicDocumentsByType } from "@prismicio/react";
import { useState, useEffect } from "react";

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0.7rem;
`;

const BlogCard = styled.div`
  width: 22rem;
  padding: 1rem 1.1rem;
  border-radius: 10px;
  border: solid 1px #e2e2e2;
  background-color: #fff;
`;

const ImageContainer = styled.div`
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Heading = styled.div`
  font-family: Gotham;
  font-size: 2rem;
  font-weight: bold;
  color: #3d3d3d;
  padding-top: 1rem;
  display: flex;
  text-align: center;
  min-height: 10.4rem;
  overflow: hidden;
`;

const Details = styled.div`
  font-size: 1rem;
  font-weight: 400;
  color: #888888;
  padding-top: 0.5rem;
  display: flex;
  text-align: center;
  min-height: 8rem;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 1.38;
  color: #3d3d3d;
  padding-top: 0.5rem;
`;

const DateDisplay = styled.div`
  flex: 1;
  text-align: center;
`;

const Link = styled.div`
  font-size: 1rem;
  color: #3a3a3a;
  padding-top: 0.2rem;
  display: flex;
  text-align: center;
  max-height: 1.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  text-decoration: underline;
`;

const CardGridLayout = styled.div`
  display: flex;
  width: 100%;
  padding-top: 2rem;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;

const LoadingContainer = styled.div`
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: black;
  font-size: 30px;
  font-weight: 800;
`;

interface DocumentItem {
  data: DocumentItemData;
}

interface DocumentItemData {
  author: { text: string }[];
  image: { url: string };
  description: { text: string }[];
  heading: { text: string }[];
  date: string;
  link: { url: string };
  category: { text: string }[];
}

interface NavProps {
  userInput: string;
}

const Card = ({ userInput }: NavProps) => {
  const [document] = useAllPrismicDocumentsByType("blogpost");
  const [displayData, setDisplayData] = useState<DocumentItem[]>([]);
  const [sortedDocument, setSortedDocument] = useState<DocumentItem[]>([]);
  const filterFunc = (tempDocument: DocumentItem[]) => {
    const filteredData = tempDocument.filter((item: DocumentItem) => {
      const result: boolean = item.data.category[0].text === userInput;
      return result;
    });
    setDisplayData((filteredData as unknown) as DocumentItem[]);
  };

  useEffect(() => {
    if (document) {
      if (sortedDocument.length === 0) {
        const tempSortedDocument = ((document as unknown) as DocumentItem[]).sort(
          (a: DocumentItem, b: DocumentItem) => {
            const adate = Date.parse(a.data.date);
            const bdate = Date.parse(b.data.date);
            const result = bdate - adate;
            return result;
          }
        );
        setSortedDocument(tempSortedDocument);
      }
      if (userInput === "All") {
        setDisplayData(sortedDocument);
      } else {
        filterFunc(sortedDocument);
      }
    }
  }, [document, userInput, sortedDocument]);

  if (displayData.length) {
    return (
      <CardGridLayout>
        {displayData.map((item: any, index: number) => {
          const documentData = item.data as DocumentItemData;
          return (
            <CardContainer key={index}>
              <BlogCard>
                <ImageContainer>
                  <img
                    src={documentData.image.url}
                    width="250"
                    height="200"
                    style={{ borderRadius: "5px" }}
                  />
                </ImageContainer>
                <Heading>{documentData.heading[0].text}</Heading>
                <Details>{documentData.description[0].text}</Details>
                <Link>
                  <a
                    href={documentData.link.url}
                    target="_blank"
                    rel="noreferrer"
                    style={{
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "nowrap"
                    }}
                  >
                    {documentData.link.url}
                  </a>
                </Link>
                <Footer>
                  <DateDisplay>{documentData.date}</DateDisplay>
                </Footer>
              </BlogCard>
            </CardContainer>
          );
        })}
      </CardGridLayout>
    );
  }
  return (
    <>
      <LoadingContainer>Loading...</LoadingContainer>
    </>
  );
};

export default Card;
