import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import Image from "next/image";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const IntroContainer = styled.div`
  background-color: #d9d9d9;
  background-image: linear-gradient(315deg, #d9d9d9 0%, #f6f2f2 74%);
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-family: monospace;
  padding-top: 2rem;
  padding-bottom: 2rem;
  font-weight: 500;
  @media only screen and (max-width: 480px) {
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-weight: 300;
  }
`;

const Title = styled.div`
  font-size: 3.3rem;
  text-align: center;
  @media only screen and (max-width: 480px) {
    font-size: 2.5rem;
  }
`;

const SubTitle = styled.div`
  font-size: 2.5rem;
  text-align: center;
  @media only screen and (max-width: 480px) {
    font-size: 1.5rem;
  }
`;

const DetailsContainer = styled.div`
  display: flex;
  padding: 3rem 4rem;
  @media only screen and (max-width: 480px) {
    padding: 2rem;
  }
`;

const Details = styled.div`
  text-align: center;
  color: #888888;
  font-size: 1.4rem;
  line-height: 1.3;
  font-family: Open Sans;
  @media only screen and (max-width: 480px) {
    font-size: 1.2rem;
  }
`;

const Intro = () => {
  const mobileView = useMediaQuery({
    query: "(max-width: 480px)"
  });
  return (
    <>
      <Container>
        <IntroContainer>
          <ImageContainer>
            {mobileView ? (
              <Image
                className="cover_image"
                src="/images/supriya.jpeg"
                alt="picture of supriya"
                width={480}
                height={420}
                layout="intrinsic"
                objectFit="cover"
                objectPosition="center"
              />
            ) : (
              <Image
                className="cover_image"
                src="/images/supriya.jpeg"
                alt="picture of supriya"
                width={1000}
                height={550}
                layout="intrinsic"
                objectFit="cover"
                objectPosition="center"
              />
            )}
          </ImageContainer>
          <TitleContainer>
            <Title>Supriya Singh</Title>
            <SubTitle>Japan based news-writer</SubTitle>
          </TitleContainer>
        </IntroContainer>
        <DetailsContainer>
          <Details>
            <em>
              I am a news writer -- English and Japanese -- based in Tokyo. Born
              in India, I moved to Japan at an early age alone to study at a
              high school in Shizuoka, central part of the country. After
              completing my masters in Tokyo, I have been working as a writer at
              Japan&apos;s biggest news agency for over 8 years and cover issues
              ranging from social news, financial market, legal trials, the
              Universal Studio Japan, and departmental stores.
              <br /> Being a journalist, I have exposure and access to the world
              that is rarely witnessed by a common man, for example resting
              space of employees of supermarket, houses of company executives
              and the Cabinet office.&#128522;
            </em>
          </Details>
        </DetailsContainer>
      </Container>
    </>
  );
};

export default Intro;
