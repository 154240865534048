import { NextPage } from "next";
import Intro from "../components/Intro";
import Layout from "../components/Layout";
import Footer from "../components/Footer";

const Home: NextPage = () => {
  return (
    <>
      <Intro />
      <Layout />
      <Footer />
    </>
  );
};

export default Home;
