import styled from "styled-components";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NavContainer = styled.div`
  display: flex;
  overflow-x: auto;
  padding: 0rem 1.5rem 2rem 1.5rem;
`;

const NavItems = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const Line = styled.div`
  width: 1.5px;
  height: 28px;
  margin-left: 1rem;
  margin-right: 1rem;
  background-color: #d8d8d8;
`;

interface Props {
  selected?: boolean;
}

const NavTitle = styled.div<Props>`
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  font-size: 1.2rem;
  font-weight: 400;
  text-transform: uppercase;
  cursor: pointer;
  text-align: center;
  background: ${(props: Props) => (props.selected ? "#d02127" : "white")};
  color: ${(props: Props) => (props.selected ? "white" : "black")};
  border-radius: ${(props: Props) => (props.selected ? "10px" : "10px")};
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;

  &:hover {
    border-radius: 10px;
    background: #d02127;
    color: white;
  }
`;

interface NavProps {
  setUserInput: (value: string) => void;
  userInput: string;
}

const Nav = ({ setUserInput, userInput }: NavProps) => {
  const onAllClickHandler = () => {
    setUserInput("All");
  };

  const onNationalClickHandker = () => {
    setUserInput("National");
  };

  const onBusinessClickHandler = () => {
    setUserInput("Business");
  };

  const onSocialClickHandler = () => {
    setUserInput("Social");
  };

  const onSportsClickHandler = () => {
    setUserInput("Sports");
  };

  const onOthersClickHandler = () => {
    setUserInput("Others");
  };
  return (
    <Container>
      <NavContainer>
        <NavItems>
          <NavTitle onClick={onAllClickHandler} selected={userInput === "All"}>
            All
          </NavTitle>
          <Line />
        </NavItems>
        <NavItems>
          <NavTitle
            onClick={onNationalClickHandker}
            selected={userInput === "National"}
          >
            National
          </NavTitle>
          <Line />
        </NavItems>
        <NavItems>
          <NavTitle
            onClick={onBusinessClickHandler}
            selected={userInput === "Business"}
          >
            Business
          </NavTitle>
          <Line />
        </NavItems>
        <NavItems>
          <NavTitle
            onClick={onSocialClickHandler}
            selected={userInput === "Social"}
          >
            Social
          </NavTitle>
          <Line />
        </NavItems>
        <NavItems>
          <NavTitle
            onClick={onSportsClickHandler}
            selected={userInput === "Sports"}
          >
            Sports
          </NavTitle>
          <Line />
        </NavItems>
        <NavItems>
          <NavTitle
            onClick={onOthersClickHandler}
            selected={userInput === "Others"}
          >
            Others
          </NavTitle>
        </NavItems>
      </NavContainer>
    </Container>
  );
};

export default Nav;
