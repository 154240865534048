import styled from "styled-components";
import Image from "next/image";

const Container = styled.div`
  padding-top: 5rem;
  padding-bottom: 3rem;
  margin-top: 3rem;
  background-color: #d9d9d9;
  background-image: linear-gradient(315deg, #d9d9d9 0%, #f6f2f2 74%);
`;

const IconContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 1.5rem;
  padding-bottom: 1rem;
`;

const SocioIcon = styled.div`
  margin-right: 1.5rem;
  margin-left: 1.5rem;
  cursor: pointer;
  @media only screen and (max-width: 480px) {
    margin-right: 0.6rem;
    margin-left: 0.6rem;
  }
`;

const Heading = styled.div`
  font-size: 2rem;
  display: flex;
  font-family: monospace;
`;

const HeadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const Footer = () => {
  return (
    <>
      <Container>
        <HeadingContainer>
          <Heading>Connect with me!</Heading>
        </HeadingContainer>
        <IconContainer>
          <SocioIcon>
            <a
              href="https://www.linkedin.com/in/supriya-singh-6925b049/"
              target="_blank"
              rel="noreferrer"
            >
              <Image
                src="/icons/linkedin.png"
                alt="linkedin logo"
                layout="fixed"
                width={35}
                height={35}
              />
            </a>
          </SocioIcon>
          <SocioIcon>
            <a
              href="https://www.instagram.com/supriya_shinchan/"
              target="_blank"
              rel="noreferrer"
            >
              <Image
                src="/icons/instagram.png"
                alt="instagram logo"
                layout="fixed"
                width={35}
                height={35}
              />
            </a>
          </SocioIcon>
          <SocioIcon>
            <a
              href="https://www.facebook.com/supriya.singh.96"
              target="_blank"
              rel="noreferrer"
            >
              <Image
                src="/icons/facebook.png"
                alt="facebook logo"
                layout="fixed"
                width={35}
                height={35}
              />
            </a>
          </SocioIcon>
          <SocioIcon>
            <a
              href="https://medium.com/@supriya.shin9"
              target="_blank"
              rel="noreferrer"
            >
              <Image
                src="/icons/medium.png"
                alt="medium logo"
                layout="fixed"
                width={35}
                height={35}
              />
            </a>
          </SocioIcon>
          <SocioIcon>
            <a
              href="https://twitter.com/Supriya_Japan"
              target="_blank"
              rel="noreferrer"
            >
              <Image
                src="/icons/twitter.png"
                alt="twitter logo"
                layout="fixed"
                width={35}
                height={35}
              />
            </a>
          </SocioIcon>
          <SocioIcon>
            <a
              href="mailto:supriya.shin9@gmail.com"
              target="_blank"
              rel="noreferrer"
            >
              <Image
                src="/icons/mail.png"
                alt="twitter logo"
                layout="fixed"
                width={35}
                height={35}
              />
            </a>
          </SocioIcon>
        </IconContainer>
        <hr />
      </Container>
    </>
  );
};

export default Footer;
